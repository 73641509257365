const catalog = {
  "categories": [
    {
      "key": "storage-backup", "label": "Storage Backup & Replication",
      "items": ["dropbox", "google-cloud-storage", "simplestorage", "aws-s3", "backblaze", "digitalocean", "wasabi", "azure", "upcloud", "exoscale", "filebase", "aws_glacier", "google-drive", "idrive-e2", "minio", "cloudflare-r2", "sftp", "storadera", "leviia", "outscale", "storj", "ovh", "synology", "supabase", "contabo", "ionos"]
    },
    {
      "key": "hosting_providers", "label": "Cloud Snapshots",
      "items": ["do_snap", "hetzner_snap", "vultr_snap", "exoscale_snap", "upcloud_snap", "aws_snap", "scaleway_snap", "amazon_lightsail", "ovh_snap", "linode_snap", "outscale_snap", "contabo_snap"]
    },
    {
      "key": "databases", "label": "Databases",
      "items": ["mysql", "postgresql", "mongodb", "redis", "digitalocean-managed-db", "amazon_rds", "amazon_aurora", "azure_sql", "googlecloud_sql", "supabase-db", "neon", "planetscale", "scalingo", "ovh_db", "ionos"]
    },
    {
      "key": "saas", "label": "SaaS App Backup",
      "items": ["github", "knack", "gitea", "gitlab"]
    },
    {
      "key": "management", "label": "Server Management Solutions",
      "items": ["runcloud", 'forge']
    },
    {
      "key": "tools", "label": "Tools",
      "items": ['raycast', 'slack', 'discord', 'pusher']
    },
    {
      "key": "storage", "label": "Storage Providers",
      "items": ["dropbox", "google-cloud-storage", "simplestorage", "aws-s3", "backblaze", "digitalocean", "wasabi", "azure", "upcloud", "exoscale", "filebase", "aws-glacier", "google-drive", "idrive-e2", "minio", "cloudflare-r2", "sftp", "storadera", "leviia", "outscale", "storj", "ovh", "synology", "supabase", "contabo", "ionos"]
    },
  ],

  "items": [
    {
      "key": "hetzner-snap",
      "label": "Hetzner",
      "image": "brands/icons/hetzner-ico.png",
      "page": "/snapshots/hetzner/",
      description: 'Server snapshots for Hetzner Cloud'
    },
    {
      "key": "dropbox",
      "label": "Dropbox",
      "image": "brands/icons/dropbox-ico.png",
      "page": "/dropbox-backup-and-restore/",
    },
    {
      "key": "google-cloud-storage",
      "label": "Google Cloud Storage",
      "image": "brands/icons/google-cloud-ico.png",
      "page": "/google-cloud-storage-gca-backup-and-restore/"
    },
    {
      "key": "simplestorage",
      "label": "SimpleStorage",
      "image": "brands/icons/simple-ico.png",
      "page": "/simplestorage-cloud-backup-storage/"
    },
    {
      "key": "aws-s3",
      "label": "AWS S3",
      "image": "brands/icons/aws-ico.png",
      "page": "/aws-s3-backup-and-restore/"
    },
    {
      "key": "amazon_lightsail",
      "label": "Amazon Lightsail",
      "image": "brands/icons/amazon-lightsail-ico.png",
      "page": "/snapshots/amazon-lightsail/",
      description: 'Server snapshots for Hetzner Cloud'
    },
    {
      "key": "backblaze",
      "label": "Backblaze",
      "image": "brands/icons/backblaze-ico.png",
      "page": "/synology-backup-and-restore/"
    },
    {
      "key": "synology",
      "label": "Synology",
      "image": "brands/icons/synology-ico.png",
      "page": "/synology-backup-and-restore/"
    },
    {
      "key": "digitalocean",
      "label": "DigitalOcean Spaces",
      "image": "brands/icons/digitalocean-ico.png",
      "page": "/digitalocean-spaces-backup-and-restore/"
    },
    {
      "key": "wasabi",
      "label": "Wasabi",
      "image": "brands/icons/wasabi-ico.png",
      "page": "/wasabi-backup-and-restore/"
    },
    {
      "key": "azure",
      "label": "Azure",
      "image": "brands/icons/azure-ico.png",
      "page": "/azure-blob-storage-backup-and-restore/"
    },
    {
      "key": "upcloud",
      "label": "UpCloud",
      "image": "brands/icons/upcloud-ico.png",
      "page": "/upcloud-backup-and-restore/"
    },
    {
      "key": "exoscale",
      "label": "Exoscale",
      "image": "brands/icons/exoscale-ico.png",
      "page": "/exoscale-backup-and-restore/"
    },
    {
      "key": "google-drive",
      "label": "Google Drive",
      "image": "brands/icons/googledrive-ico.png",
      "page": "/google-drive-backup-and-restore/"
    },
    {
      "key": "filebase",
      "label": "Filebase",
      "image": "brands/icons/filebase-ico.png",
      "page": "/filebase-backup-and-restore/"
    },
    {
      "key": "aws-glacier",
      "label": "AWS Glacier",
      "image": "brands/icons/aws-glacier-ico.png",
      "page": "/integrations/aws-s3-glacier-backup/"
    },
    {
      "key": "idrive-e2",
      "label": "IDrive E2",
      "image": "brands/icons/idrive-e2-ico.png",
      "page": "/idrive-e2-backup-and-restore/"
    },
    {
      "key": "minio",
      "label": "MinIO",
      "image": "brands/icons/minio-ico.png",
      "page": "/minio-backup-and-restore/"
    },
    {
      "key": "cloudflare-r2",
      "label": "Cloudflare R2",
      "image": "brands/icons/cloudflare-ico.png",
      "page": "/cloudflare-r2-backup-and-restore/"
    },
    {
      "key": "sftp",
      "label": "SFTP",
      "image": "brands/icons/sftp-ico.png",
      "page": "/sftp-backup-and-restore/"
    },
    {
      "key": "storadera",
      "label": "Storadera",
      "image": "brands/icons/storadera-ico.png",
      "page": "/#"
    },
    {
      "key": "leviia",
      "label": "Leviia",
      "image": "brands/icons/leviia-ico.png",
      "page": "/#"
    },

    {
      "key": "outscale",
      "label": "Outscale",
      "image": "brands/icons/outscale-ico.png",
      "page": "/#"
    },

    {
      "key": "storj",
      "label": "Storj",
      "image": "brands/icons/storj-ico.png",
      "page": "/#"
    },

    {
      "key": "supabase",
      "label": "Supabase",
      "image": "brands/icons/supabase-ico.png",
      "page": "/#"
    },

    {
      "key": "ionos",
      "label": "Ionos",
      "image": "brands/icons/ionos-ico.png",
      "page": "/#"
    },

    {
      "key": "contabo",
      "label": "Contabo",
      "image": "brands/icons/contabo-ico.png",
      "page": "/#"
    },

    {
      "key": "do_snap",
      "label": "DigitalOcean",
      "image": "brands/icons/digitalocean-ico.png",
      "page": "/snapshots/digitalocean/",
      description: 'Droplet & Volume Snapshots for DigitalOcean'
    },
    {
      "key": "hetzner_snap",
      "label": "Hetzner",
      "image": "brands/icons/hetzner-ico.png",
      "page": "/snapshots/hetzner/",
      description: 'Cloud VPS snapshots for Hetzner'
    },
    {
      "key": "vultr_snap",
      "label": "Vultr",
      "image": "brands/icons/vultr-ico.png",
      "page": "/snapshots/vultr/",
      description: 'Cloud Compute snapshots for Vultr'
    },
    {
      "key": "exoscale_snap",
      "label": "Exoscale",
      "image": "brands/icons/exoscale-ico.png",
      "page": "/snapshots/exoscale/",
      description: 'Server snapshots for Exoscale'
    },
    {
      "key": "upcloud_snap",
      "label": "UpCloud",
      "image": "brands/icons/upcloud-ico.png",
      "page": "/snapshots/upcloud/",
      description: 'Storage snapshots for UpCloud'
    },
    {
      "key": "aws_snap",
      "label": "AWS EC2",
      "image": "brands/icons/aws-ico.png",
      "page": "/snapshots/aws-ec2/",
      description: 'Instance & volume snapshots for AWS EC2'
    },
    {
      "key": "scaleway_snap",
      "label": "Scaleway",
      "image": "brands/icons/scaleway-ico.png",
      "page": "/snapshots/scaleway/",
      description: 'Server & volume snapshot for Scaleway'
    },
    {
      "key": "scaleway_snap",
      "label": "Scaleway",
      "image": "brands/icons/scaleway-ico.png",
      "page": "/snapshots/scaleway/",
      description: 'Server & volume snapshot for Scaleway'
    },
    {
      "key": "linode_snap",
      "label": "Linode",
      "image": "brands/icons/linode-ico.png",
      "page": "/snapshots/linode-akamai/",
      description: 'Server snapshot for Akami Linode'
    },
    {
      "key": "ovh_snap",
      "label": "Ovh",
      "image": "brands/icons/ovh-ico.png",
      "page": "/snapshots/ovh/",
      description: 'Server & volume snapshot for Ovh'
    },
    {
      "key": "contabo-snap",
      "label": "Contabo",
      "image": "brands/icons/contabo-ico.png",
      "page": "/#",
      description: 'Instance snapshots for Contabo'
    },
    {
      "key": "mysql",
      "label": "MySQL",
      "image": "brands/icons/mysql-ico.png",
      "page": "/mysql-backup/",
      description: 'Self-hosted & managed MySQL & MySQL Binlog backup and restore'
    },
    {
      "key": "postgresql",
      "label": "PostgreSQL",
      "image": "brands/icons/postgresql-ico.png",
      "page": "/postgresql-backup/",
      description: 'Self-hosted & managed PostgreSQL backup and restore'
    },
    {
      "key": "mongodb",
      "label": "MongoDB",
      "image": "brands/icons/mongodb-ico.png",
      "page": "/mongodb-backup/",
      description: 'Self-hosted & managed MongoDB backup and restore'
    },

    {
      "key": "redis",
      "label": "Redis",
      "image": "brands/icons/redis-ico.png",
      "page": "/redis-backup/",
      description: 'Self-hosted & managed Redis backup and restore'
    },

    {
      "key": "digitalocean-managed-db",
      "label": "DigitalOcean Managed Database",
      "image": "brands/icons/digitalocean-ico.png",
      "page": "/digitalocean-backup/",
      description: 'DigitalOcean Managed database backup for MySQL, PostgreSQL, MongoDB'
    },

    {
      "key": "amazon_rds",
      "label": "AWS RDS",
      "image": "brands/icons/aws-ico.png",
      "page": "",
      description: 'Amazon RDS database backup for MySQL, PostgreSQL, MongoDB'
    },

    {
      "key": "amazon_aurora",
      "label": "AWS Aurora",
      "image": "brands/icons/aws-ico.png",
      "page": "",
      description: 'Amazon (AWS) Aurora database backup'
    },

    {
      "key": "googlecloud_sql",
      "label": "Google Cloud SQL",
      "image": "brands/icons/google-cloud-ico.png",
      "page": "",
      description: 'Google Cloud database backup for MySQL, PostgreSQL, MongoDB'
    },

    {
      "key": "supabase-db",
      "label": "Supabase",
      "image": "brands/icons/supabase-ico.png",
      "page": "/database/supabase",
      description: 'Supabase PostgreSQL backup'
    },

    {
      "key": "neon",
      "label": "Neon",
      "image": "brands/icons/neon-ico.png",
      "page": "/database/neon",
      description: 'Neon Serverless Postgres backup'
    },

    {
      "key": "scalingo",
      "label": "Scalingo",
      "image": "brands/icons/scalingo-ico.png",
      "page": "/database/dbaas-backup/#",
      description: 'Scalingo Postgres, Mysql, Mongo, Redis backup'
    },

    {
      "key": "ovh_db",
      "label": "OVH",
      "image": "brands/icons/ovh-ico.png",
      "page": "/database/dbaas-backup/#",
      description: 'OVH Postgres, Mysql, Redis backup'
    },

    {
      "key": "planetscale",
      "label": "Planetscale",
      "image": "brands/icons/planetscale-ico.png",
      "page": "/database/planetscale-backup",
      description: 'PlanetScale Serverless MySQL backup'
    },

    {
      "key": "azure_sql",
      "label": "MS Azure SQL",
      "image": "brands/icons/azure-ico.png",
      "page": "",
      description: 'Azure database backup for MySQL, PostgreSQL, MongoDB'
    },

    {
      "key": "runcloud",
      "label": "RunCloud",
      "image": "brands/icons/runcloud-ico.png",
      "page": "/integrations/runcloud-backup/",
      description: 'Flexible backup for all your RunCloud websites.'
    },
    {
      "key": "forge",
      "label": "Forge",
      "image": "brands/icons/laravel-ico.png",
      "page": "",
      description: 'Flexible backup for all your Forge websites.'
    },
    {
      "key": "raycast",
      "label": "Raycast",
      "image": "brands/icons/raycast-ico.png",
      "page": "/blog/experience-simplebackups-like-never-before-with-raycast-supercharge-your-productivity/",
      description: 'Control & trigger your backups right from Raycast.'
    },
    {
      "key": "slack",
      "label": "Slack",
      "image": "brands/icons/slack-ico.png",
      "page": "",
      description: 'Get your backup notifications on Slack Channels.'
    },
    {
      "key": "discord",
      "label": "Discord",
      "image": "brands/icons/discord-ico.png",
      "page": "",
      description: 'Get your backup notifications on Discord Channels.'
    },
    {
      "key": "pusher",
      "label": "Pusher",
      "image": "brands/icons/pusher-ico.png",
      "page": "",
      description: 'Get your backup notifications on Pusher.'
    },

    {
      "key": "knack",
      "label": "Knack",
      "image": "brands/icons/knack-ico.png",
      "page": "/saas-backup/knack/",
      description: 'Connect & backup all your Knack data.'
    },

    {
      "key": "gitea",
      "label": "Gitea",
      "image": "brands/icons/gitea-ico.png",
      "page": "/saas-backup/gitea/",
      description: 'Connect & backup all your Gitea data.'
    },

    {
      "key": "github",
      "label": "GitHub",
      "image": "brands/icons/github-ico.png",
      "page": "/saas-backup/github/",
      description: 'Connect & backup all your GitHub data.'
    },

    {
      "key": "gitlab",
      "label": "GitLab",
      "image": "brands/icons/gitlab-ico.png",
      "page": "/saas-backup/gitlab/",
      description: 'Connect & backup all your GitLab data.'
    },

  ],

  content: {
    storage_description: 'Store your backups on ##storage##.',
    storage_backup_description: 'Backup or migrate your data from ##storage##.',
  }
}

export default catalog;
